/* 48em = 768px */
/*45em*/
/* 58em = 930px */

@media (min-width: 58em) {
    :root{
      font-size: 15px;
    }

    h1{
      font-size: 1.4rem;
    }
    
    h2{
      font-size: 1.1rem;
      padding-top: 0%;
    }

    h3{
      color: white;
      text-align: center;
      font-size: 1.2rem;
      /*position: relative;*/
      background-color: black;
      width: 60%;

      padding-top: 1.8%;
      padding-bottom: 1.8%;

      margin-top: -1%;

      margin-left: 15%;
      margin-right: 15%;
      /*
      width: 60%;
      padding-top: 2%;
      left: 20%
      */
      /*padding-top: 10%;*/
    }

    h4{
      color: white;
      text-align: center;
      font-size: 1.2rem;
      /*position: relative;*/

      padding-top: 1.8%;
      padding-bottom: 1.8%;

      
      /*margin-top: -1%;*/

      margin-left: 15%;
      margin-right: 15%;
    }

    h5{
    background-color: black;
    width: 40%;

    margin-top: 5%;
    margin-bottom: -9%;
    margin-left: 10%;
    margin-right: auto;
    }

    h6{
      margin-top: 5%;
    }

    /*
    .p2{
      text-align: left;
    }
    */
    

    .header li {
      float: left;
    }
    
    .header li a {
      /*padding: 20px 30px;*/
      
      padding: 10px 20px;
      
      margin-top: 7px;
      margin-bottom: 7px;
      margin-left: 15px !important;

      text-align: center;
      text-indent: 0%;

      border-bottom: none !important;
      margin-right: 0%;
      
    }
    
    .header > ul > li > a:hover{
      border-radius: 10px; /*30px ???*/
    }

    .headerlia{
      color:white;
    }

    .highlight{
      background-color: grey;
      border-radius: 10px; /*30px ???*/
      color: black;
    }

    .header .menu {
      clear: none;
      
      /*float: right;*/
      margin-left: 50%;

      max-height: none;
    }

    .header .menu-icon {
      display: none;
    }

    .decoration_nav1{
      display: none;

    }
    .decoration_nav2{
      display: none;
      
    }
    .decoration_nav3{
      display: none;
      
    }

    .menu{
      max-height: 240px;
      display: block!important;
    }

    /*------------Body---------------*/
    .logo_home{
      width: 50%;
      padding-left: 25%;
      padding-right: 25%;
      padding-top: 1%;
      padding-bottom: 1%;
    }
    /*------------Slideshow----------*/
    /* Slideshow container */
    .slideshow-container {
      margin-top: 83px;
      margin-left: 15%;
      width: 70%; /*100%*/
      height: 70%; /*100%*/
    
      /*max-height: 100vh;*/
      /*margin: auto;*/

      /*margin-bottom: 10%;*/

      overflow: hidden;
      
      padding-right: 0%;
      /*background-color: blue;*/
      
    }

    .slidetitel{
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .slideimg{
      width: 100%;
    }

    .prev,
    .next {
    font-size: 35px;
    position: absolute;
      /*margin-top: -34%; */
    
    }

    .next{
      float: right;
      
    }
    
    .prev{
      float: left;
    }

    .prev:hover,
    .next:hover {
      background-color: rgba(255, 255, 255, 0.7);
    }
    /*------------/Slideshow----------*/
    /*------------Slideshow2----------*/
    .slideimg2{
      width: 80%;
      height: 60%;
      position: relative;
      margin-left: 10%;
      margin-top: 4%;
      margin-right: 0%;
      padding-top: 6%;
    }

    .slideshow-container2 {
      width: 50%;
      /*max-width: 1000px;*/
      position: relative;
      margin-left: 5%;
      margin-top: 5%;
    }

    .prev2, .next2 {
      cursor: pointer;
      position: absolute;
      top: 50%;
      width: auto;
      padding: 16px;
      margin-top: -70px;
      color: black;
      font-weight: bold;
      font-size: 30px;
      transition: 0.6s ease;
      border-radius: 0 3px 3px 0;
      user-select: none;
    
      margin-top: -2%;
      /*margin-left: 10%;*/
     
    
      text-shadow: 0 0 5px white;
    }

    .prev2{
      margin-left: 10%;
    }

    .next2{
      margin-right: 10%;
    }

    .dot_container{
      margin-left: 28%;
      text-align: left;
    }

    .prev2:hover, .next2:hover {
      background-color: rgba(0,0,0,0.8);
    }
    /*-----------/Slideshow2----------*/

    .grid-container {
      padding-top: 10%;
      display: grid;
      grid-template-columns: 50% 50%;
      /*grid-template-rows: 35% 50%;*/
      /*background-color: #2196F3;*/
      /*padding: 10px;*/
      
    }
    .grid-item {
      /*background-color: rgba(255, 255, 255, 0.8);*/
      /*border: 1px solid rgba(0, 0, 0, 0.8);*/
      /*padding: 20px;*/
      /*font-size: 30px;*/
      /*text-align: center;*/
    }
    
    .h2ueber{
      padding-right: 10%;
    }

    .h2vermietung{
      padding-left: 10%;
    }

    .imgteam{
      padding-left: 10%;
      padding-right: 20%;
    }

    .imgvermietung{
      padding-right: 10%;
      padding-left: 20%;
    }
    
    /*Vermietung*/
    .container_vermietung {
      display: grid;
      grid-template-columns: 50% 50%;

      margin-top: 7%;
      /*grid-gap: 10px;*/
      /*background-color: #2196F3;*/
    }
    
    .item_vermietung {
      margin-top: 7% !important; /*war auf 3%*/
      /*background-color: rgba(255, 255, 255, 0.8);
      text-align: center;*/
      /*padding: 20px 0;*/
    }

    .vermietung_produkt{
      padding-top: 3%;
      padding-left: 10%;
      padding-right: auto;
      width: 40%;
    }

    .vermietung{
      padding-left: 26% !important;
      padding-right: 10% !important;
      width: 60% !important;
    }

    .vermietung_rechts{
      padding-left: 14% !important;
      padding-right: 20% !important;
      width: 60% !important;
    }

    .h3rechts{
      margin-left: 14%;
    }

    .h3links{
      margin-left: 26%;
    }


    .h4rechts{
      margin-left: 5%;
    }

    .h4links{
      margin-left: 26%;
    }

    .picUeber{
      margin-top: 10%;
      width: 30%;
      padding-left: 35%;
      padding-right: auto;
    }

    .h4Ueber{
      margin-top: 0%;
    }

    .titelUeber{
      margin-bottom: 5%;
    }

    .uebermargin{
      margin-top: 10%;
    }

        
    h4 > p {
      text-align: left;
      margin-top: 0%;
      margin-left: 28%;
      margin-right: 20%;
      line-height: 2;
    }

    
    .teamtitel{
      margin-bottom: -5%;
      text-indent: 13%;
    }

    .teamdeco{
      margin-top: 5.5%;
      left: 12.5%;
      width: 100px;
    }

    .kontaktdeco{
      left: 42%;
    }


    iframe{
      margin-top: 10%;
      width: 50%;
      margin-left: 25%;
      margin-right: 25%;
    }

        
    .closed{
      color: white;
      fill: white;

      position: absolute;
      margin-left: 80%;
      margin-top: 15px;
    }

         
    .open{
      color: white;
      fill: white;

      position: absolute;
      margin-left: 80%;
      margin-top: 15px;

      transform: rotate(180deg);
    }

    .konttd{
      width: 30%;
    }

    .price{
      position: absolute;
      margin-left: 55%;
      margin-top: -21%;
      line-height: 2;

      width: 45%;
    }
   
    .spaceing{
      line-height: 1.5;
      padding-bottom: 5%;
    }

    .mietpreis{
      margin-top: -10%;
    }
/*
    .mietpreis{
      margin-left: -70%;
      margin-top: -15%;
      margin-bottom: 10%;
    }
    */

    /*------------/Body---------------*/
    /*--------------Footer--------------*/
    footer{
      margin-bottom: 0%;
      margin-top: 15%;
    }

    .decoration{
      width: 30%;
      top: 7%;
    }

    footer > table{
      display: none;
    }

    .decoration_2{
      display: none;
    }
    
    .kontaktlink{
      /*vertical-align: bottom;*/
      /*margin-left: 10%;*/
      left: 68%;
      position: absolute;
      display: block;
      top: 25%;
    }

    .kontaktlink > li{
      /*margin: 20%;*/
      line-height: 1.7;
    }

    .kontaktlink > li > a{
    color: white;
    text-decoration: none;
    }

    .kontaktlink > li > a:hover{
    color: rgb(150, 150, 150)
    }

    .kntmargin{
      margin-top: 10% !important;
    }

    .decoration_3{
      width: 25%;
      height: 0.5%;
      left: 68%;
      position: absolute;
      background-color: white;
      top: 36%;
    }

    .decoration_4{
      width: 25%;
      height: 0.5%;
      left: 68%;
      position: absolute;
      background-color: white;
      top: 48%;
    }

    /*------------Footer------------*/

    .createdby{
      padding: 8px;
      width: 82.3%;
      margin-left: 10%;
    }
     
    
    
  }
