/*@import "~react-image-gallery/styles/css/image-gallery.css";*/
@import "~react-image-gallery/styles/css/image-gallery.css";
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}


*{
    margin:0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: Arial, Helvetica, sans-serif;
   
    /*font-family: Comic Sans MS, cursive;*/

}
html{
  scrollbar-color: grey black;
  /*overflow-y: scroll;*/
}

body{
    /*overflow-x: hidden;*/
    margin: 0;
    min-height: 100vh;
    background: linear-gradient(#000000, #08298A);
    /*display: flex;
    flex-direction: column;*/
}

:root{
    font-size: 15px;
}
/*p*/
.p{
  color: white;
  text-align: center;
  line-height: 1.3;

  padding-left: 20%;
  padding-right: 20%;
}

.p1{
  color: white;
  text-align: center;
  /*line-height: 1.3;*/

  padding-top: 3%;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 5%;
}

.p2{
  color: white;
  text-align: center;
  /*line-height: 1.3;*/

  padding-top: 2%;
  padding-left: 10%;
  padding-right: 10%;
}

.p2 > a{
  color: white;
}

.p2 > a:hover{
  color: grey;
}

.p3{
  color: white;
  text-align: left;
  line-height: 1.5;

  /*padding-top: 2%;*/
  padding-left: 20%;
  padding-right: 20%;
}

.p4{
  color: white; 
  margin-left: 90%;
  margin-top: -15px;
  font-weight: bold;

  transform: rotate(-180deg);
  
}

.p5{
  color: white;
  text-align: center;
  font-weight: bold;
}

.colWhite{
  color: white;
}

h1{
  color: white;
  text-align: center;
  font-style: bold;
  font-size: 1.2rem;

  padding-left: 15%;
  padding-right: 15%;

  padding-top: 5%;
  padding-bottom: 2%;
}

h2{
  color: white;
  text-align: center;
  font-size: 1.2rem;

  padding-top: 10%;
}

h3{
  color: white;
  text-align: center;
  font-size: 1.2rem;
  /*position: relative;*/
  background-color: black;
  width: 70%;

  padding-top: 3%;
  padding-bottom: 3%;

  margin-top: -1%;

  margin-left: 15%;
  margin-right: 15%;
  

  /*padding-top: 10%;*/
}

h4{
  color: white;
  text-align: center;
  font-size: 1.2rem;
  /*position: relative;*/


  padding-top: 3%;
  padding-bottom: 3%;

  margin-top: 2%;

  
}

h5{
  color: white;
  text-align: center;
  font-size: 1.2rem;
  /*position: relative;*/
  background-color: black;
  width: 70%;

  margin-top: 5%;
  margin-bottom: -5%;
  margin-left: 15%;
  margin-right: 15%;
}

h6{
  margin-top: 5%;
  /**/
  margin-bottom: 2%;
  /**/
  color: white;
  text-align: center;
  font-weight: bold;
}

.background{
  margin-left: 10%;
  margin-right: 10%;

  background-color: black;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 80%;
}

.margin-top{
  margin-top: 15px; 
}

.top-100{
  margin-top: 100px;
}


/*
.zeilenumbruch{
    padding: 2%;
}
*/
.umbruch{
  padding-bottom: 20px;
}


header{
    width: auto;
    height: auto;
    border: 3px solid black;
    
    position: absolute;
    background-color: none;
    text-align: center;

}
/*
.logo{
    width: 50%;
    position: relative;
}
*/

/*------------------------Body---------------------------*/
.placeholder{
  padding: 31px; /*31px*/

  /*border: 3px solid white;*/
  background-color: white;
  opacity: 0;
  /*position: relative;*/
}

.logo_home{
  position: relative;
  width: 75%;
  /*border: 3px solid rgb(51, 51, 51);*/
  
  margin-top: 0%;
  padding-left: 12.5%;
  padding-right: 12.5%;
  padding-top: 3%;
  padding-bottom: 3%;
}

/*-----------Slideshow---------------*/
.slidetitel{
  font-size: 1.3rem;
  background-color: rgba(211, 211, 211, 0.7);
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  /*height: 10%;*/
  position: absolute;
  text-align: center;

  z-index: 1;
}

.slidetitel > p{
  /*margin-top: 10%;*/
}



.slideimg{
  width: 120%;
  height: 30%;
}

.mySlides {
  display: none;
}
/* Slideshow container */
.slideshow-container {
  margin-top: 63px;
  width: 100%; /*100%*/
  height: 100%; /*100%*/
  /*max-height: 100vh;*/
  /*margin: auto;*/

  margin-bottom: 10%;

  /*overflow: hidden;*/
  
  /*padding-right: 150%;*/
  /*background-color: blue;*/
  
}
/* The dots/bullets/indicators */
.slideDots{
  text-align:"center";
  margin:"-5vh";
}


.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: none; /*inline-block*/
  transition: background-color 0.6s ease;

}

.active {
  background-color: #717171;
}

/* Fading animation */
/*
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
      opacity: .4
  }

  to {
      opacity: 1
  }
}

@keyframes fade {
  from {
      opacity: .4
  }

  to {
      opacity: 1
  }
}
*/

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {

  .prev,
  .next,
  .text {
      font-size: 11px
  }
}

/* Next & previous buttons*/
.prev,
.next {
  cursor: pointer;
  /*position: absolute;*/
  /*top: 50%;*/
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: black;
  font-weight: bold;
  font-size: 30px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;

  text-shadow: 0 0 5px white;
}



.next{
  float: right;
  position: absolute;
  /*margin-top: -30%; */
}

.prev{
  float: left;
  position: absolute;
  /*margin-top: -30%;*/
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: none;
}
/*------------------/Slideshow------------------*/
/*----------------Slidesshow2----------------*/

.slideimg2{
  width: 70%;
  height: 30%;
  position: relative;
  margin-left: 15%;
  margin-right: 15%;
  padding-top: 6%;
}

.mySlides2 {
  display: none;
}

.slideshow-container2 {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev2, .next2 {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: black;
  font-weight: bold;
  font-size: 30px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;

  margin-left: 15%;
  margin-right: 15%;

  text-shadow: 0 0 5px white;
}

/* Position the "next button" to the right */
.next2 {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* Caption text */
.text2 {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext2 {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.dot_container{
  text-align: center;
}

/* The dots/bullets/indicators */
.dot2 {
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 0 2px;
  background-color: #474747;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active2, .dot2:hover {
  background-color: #bbb;
}


/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev2, .next2,.text2 {font-size: 11px}
}

/*-------------------/Slidesshow2----------------*/
.grid-container {
  padding-top: 10%;
  display: grid;
  grid-template-columns: 100%;
  /*background-color: #2196F3;*/
  /*padding: 10px;*/
}

article > a{
  text-decoration: none;
}

section > article > a > img{
  width: 70%;
  margin-top: 5%;
  padding-left: 15%;
  padding-right: 15%;
}

.item_vermietung > div > img{
  width: 70%;
  margin-top: 5%;
  padding-left: 15%;
  padding-right: 15%;
}

.item_vermietung > div > img:hover{
  opacity: 0.8;
  cursor: pointer;
}

section > article > a > img:hover{
  opacity: 0.8;
}

section > article > img{
  width: 70%;
  margin-top: 5%;
  padding-left: 15%;
  padding-right: 15%;
}

.h2ueber:hover,
.h2vermietung:hover,
.h3vermietung:hover,
.h3rechts:hover,
.h3links:hover{
  color: grey;
  cursor: pointer;
}

.uebermargin{
  margin-top: 20%;
}

/*
.imgvermietung{
  
}
*/
.vermietung_produkt{
  margin-top: 7%;
}

.vermietung{
  margin-top: 10%;
}

.vermietung_rechts{
  margin-top: 10%;
}


.teamtitel{
  margin-top: 15%;
  color: white;
  font-size: 1.3rem;
  text-indent: 15%;
}

.teamdeco{
  margin-top: 1%;
  width: 98px;
  height: 0.3%;
  left: 14%;
  background-color: white;
  position: absolute;
}

h4 > p {
  text-align: left;
  margin-top: 5%;
  margin-left: 20%;
  margin-right: 20%;
}

.picUeber{
  margin-top: 15%;
}

.picqr{
  margin-top: 5%;
  width: 100%;
}

.kontlink{
  color: white;

}

.kontlink:hover{
  color: grey;
}

.kontakttitel{
  margin-top: 15%;
  color: white;
  font-size: 1.3rem;
  text-align: center;

  position: relative;
  padding-bottom: 0.5%;
  border-bottom: 2px solid white;
  width: 250px;
}

.kontakttitel2{
  margin-top: 15%;
  color: white;
  font-size: 1.3rem;
  text-align: center;

  position: relative;
  padding-bottom: 0.5%;
  border-bottom: 2px solid white;
  width: 250px;
}

.kontakttext{
  color: white;
  margin-top: 5%;
  margin-left: 3%;
  position: relative;
  line-height: 1.5;
}

.kontakttext2{
  color: white;
  margin-top: 10%;
  margin-left: 3%;
  position: relative;
  line-height: 1.5;
}

.kntmargin{
  margin-top: 25% !important;
}

.placeholder2{
  margin-top: 7%;
  width: 220px;
  margin-left: auto;
  margin-right: auto;
}

.placeholder3{
  margin-top: 150px;
  width: 130px;
  margin-left: auto;
  margin-right: auto;
}

.placeholder4{
  margin-top: 7%;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}

iframe{
  margin-top: 10%;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

/*
.closed{
  color: white;
  fill: white; /*Für SVG*//*

  position: absolute;
  margin-left: 75%;
  margin-top: 15px;
}

.closed:hover,
.open:hover{
  cursor: pointer;
}

.open{
  color: white;
  fill: white; /*Für SVG*//*

  position: absolute;
  margin-left: 75%;
  margin-top: 15px;

  transform: rotate(180deg);
  display: none;
}
*/

.tableDisplay{
  display: var(--table-display, none); /*table-cell*/
}




.kontTable{
  width: 70%;
  color: white;
  margin-top: 10%;
  margin-left: 15%;
  margin-right: 15%;
}

.kontTable,
.kontth,
.konttd,
.konttd_2{
  border: 1px solid black;
  border-collapse: collapse;

}

.kontth{
  height: 50px;
  text-align: center;
  background-color: black;
}

.kontth:hover{
  cursor: pointer;
}


.konttd{
  background-color: white;
  color: black;
  height: 50px;
  padding-left: 5px;

  width: 50%;


  /*display: none;
  /*visibility: hidden*/
}

.konttd_2{
  background-color: white;
  color: black;
  height: 50px;
  padding-left: 5px;


  /*display: none;
  /*visibility: hidden*/
}

.spaceing{
  line-height: 1;
  padding-bottom: 5%;
}

.indicator{
  height: 10px;
  width: 10px;
  background-color: rgb(94, 94, 94);
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
}

.indicator.active {
  color: #fff;
  background: #fff;
}



/*------------------------/Body--------------------------*/


footer{
   
    width: 100%;
    height: auto;
    /*bottom: -2%;*/
    /*border: 3px solid black;*/

    position: relative;
    /*background: rgba(58,95,205,0);*/
    background-color: none;
    font-size: 1rem;

    margin-top: 20%;
    margin-bottom: 15%;

    /*
    justify-content: flex-end;

/*
    padding-top: 2%;
    padding-bottom: 2%;
    background: rgba(255, 255, 0, 0.5);
    display: flex;
    justify-content: flex-end;
 
    margin-top: auto;
  
    padding-right: 50%;
    */

}

footer > ul{
    text-align: left;
    list-style-type: none;
   /* position: relative;*/
   padding-bottom: 5%;
}

.decoration{
    width: 75%;
    height: 0.5%;
    left: 10%;
    position: absolute;
    margin-top: 5px;
    background-color: white;
    top: 8%;

}

footer > p{
    margin-left: 10%;
    color: white;
}

.kontaktdaten{
    margin-left: 10%; 
    margin-top: 1%;
    line-height: 1.7;
    color: white;
 
}

footer > table{
    margin-left: 10%;
    border-collapse: collapse;
    width: 50%;
}


th > a{
    color: white;
    text-decoration: none;
}

th > a:hover{
  color: rgb(150, 150, 150);
}

th{
    text-align: left;
    padding-right: 5%;
}

.decoration_2{
    width: 75%;
    height: 0.7%;
    left: 10%;
    background-color: white;
    top: 101%;
    position: absolute;
    /*margin-bottom: 5%;*/
}

.kontaktlink{
  display: none;
}
/*--------------------Nav Bar------------------------------*/
  .header {
    background-color: black;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,.1);
    position: fixed;
    width: 100%;
    z-index: 11;
  }
  
  .header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: black;
    
  }
  
  .header li a {
    
    margin-left: 13%;
    margin-right: 13%;
    
    
    display: block;
    padding: 20px 20px;
    /*border-right: 1px solid #f4f4f4;*/
    /*border-right: 1px solid;*/
    text-decoration: none;
    /*color: white;*/
    
    /*margin-right: 50%;*/
    
    text-align: left;

    border-bottom: 2px white solid;
    /*text-indent: 13%;*/

    /*border-bottom: solid 1px white;*/
    
  }

  .header li:last-child a {
    border-bottom: none !important;
  }


  .headerlia, .highlight{
    color: white;
  }

  /*
  .decoration_nav1{
    width: 70%;
    height: 0.5%;
    left: 15%;
    position: absolute;
    background-color: white;
    top: 40%;

    /*display: none;*/

/*
    display: var(--lines-display, none); /*table-cell*/
    /*
  }

  .decoration_nav2{
    width: 70%;
    height: 0.5%;
    left: 15%;
    position: absolute;
    background-color: white;
    top: 60%;

    /*display: none;*//*
    display: var(--lines-display, none); /*table-cell*//*
  }

  .decoration_nav3{
    width: 70%;
    height: 0.5%;
    left: 15%;
    position: absolute;
    background-color: white;
    top: 80%;

    /*display: none;*//*
    display: var(--lines-display, none); /*table-cell*//*
  }*/
  
  .header li a:hover,
  .header .menu-btn:hover {
    background-color: white;
  }

  .header > ul > li > a:hover{
    color: black;
    
  }
  
  .header .logo {
    /*float: left;
    padding: 10px 20px;*/

    /*height: 100%;*/

    /*width: 10%;*/
    position: relative;
    float: left;
    left: 10%;

    width: 110px;


  }
  
  /* menu */
  
  .header .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
    /*margin-top: 2%;*/
  }
  
  /* menu icon */
  
  .header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 23px 20px;
    position: relative;
    user-select: none;
  }
  
  .header .menu-icon .navicon {
    background: white;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
  }
  
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    background: white;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
    
  }
  
  .header .menu-icon .navicon:before {
    top: 5px;
  }
  
  .header .menu-icon .navicon:after {
    top: -5px;
  }

  .header .menu-icon .navicon:last-child:before {
    margin-top: 7.5px;
  }
  
  .header .menu-icon .navicon:last-child:after {
    display: none;
  }
  
  /* menu btn */
  
  .header .menu-btn {
    display: none;
  }
  
  .header .menu-btn ~ .menu {
    max-height: 240px;
  }
  
  .header .menu-btn ~ .menu-icon .navicon {
    background: transparent;
  }
  
  .header .menu-btn ~ .menu-icon .navicon:before {
    /*transform: rotate(-45deg);*/
    top: 5px;
  }

  .header .menu-btn ~ .menu-icon .navicon:not(:last-child):not(:first-child) {
    /*transform: rotate(-45deg);*/
    margin-top: 10px;
  }
  
  
  .header .menu-btn ~ .menu-icon .navicon:after {
    margin-top: 5px;
    /*transform: rotate(45deg);*/
  }
  
  .header .menu-btn ~ .menu-icon:not(.steps) .navicon:before,
  .header .menu-btn ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }

  .menu{
    max-height: 240px;
    display: var(--menu-display, none);
  }


/*-------------------Bild bei Klick vergrössern-----------------*/

/*-------------------Bild bei Klick vergrössern-----------------*/

.impressTxt{
  margin-left: 10%;
  margin-right: 10%;
}

.createdby{

  padding-top: 5%;
  padding-bottom: 5%;
  /*background-color: #000000d9;*/
  width: 75%;
  margin-left: 10%;
  border-top: 1px solid white;
  color: white;
  text-align: center;
  font-size: 15px;
}
 

  
  